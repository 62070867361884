import React, { useEffect, useState } from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { TextField, List, ListItem, ListItemText, Paper } from '@mui/material'

const AddressAutocomplete = ({ label, onAddressSelect, value = '' }) => {
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value)
    let addressLine1 = ''
    let city = ''
    let state = ''
    let zip = ''

    for (let component of results[0].address_components) {
      const componentType = component.types[0]
      switch (componentType) {
        case 'street_number': {
          addressLine1 = component.short_name
          break
        }
        case 'route': {
          addressLine1 = `${addressLine1} ${component.short_name}`
          break
        }
        case 'locality':
          city = component.long_name
          break
        case 'administrative_area_level_1':
          state = component.short_name
          break
        case 'postal_code':
          zip = component.short_name
          break
        default:
          console.warn(`Unhandled address component: ${componentType}`)
          break
      }
    }

    const latLng = results[0].geometry.location
    const latitude = latLng.lat()
    const longitude = latLng.lng()

    setInputValue(addressLine1)

    onAddressSelect({
      addressLine1,
      city,
      state,
      zip,
      latitude,
      longitude,
    })
  }

  return (
    <PlacesAutocomplete
      value={inputValue}
      onChange={setInputValue}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <TextField
            {...getInputProps({
              label: label,
              fullWidth: true,
              variant: 'outlined',
              style: { marginBottom: '10px' },
            })}
            required
          />
          <div style={{ position: 'absolute', zIndex: 2 }}>
            {loading && <div>Loading...</div>}
            {suggestions.length > 0 && (
              <Paper>
                <List>
                  {suggestions.map((suggestion, idx) => (
                    <ListItem
                      {...getSuggestionItemProps(suggestion)}
                      key={idx}
                      button
                    >
                      <ListItemText primary={suggestion.description} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            )}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default AddressAutocomplete
