import React from 'react'
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material'
import { useAtom } from 'jotai'
import { paymentStatusAtom } from 'constants/atoms'
const PaymentStatusFilter = () => {
  const [paymentStatus, setPaymentStatus] = useAtom(paymentStatusAtom)
  return (
    <FormControl
      variant="outlined"
      size="medium"
      sx={{ minWidth: '110px', marginRight: '10px'}}
    >
      <InputLabel id="payment-status-label">Payment Status</InputLabel>
      <Select
        labelId="payment-status-label"
        value={paymentStatus}
        onChange={(event) => setPaymentStatus(event.target.value)}
        label="Payment Status"
      >
        <MenuItem value="PAID">Paid</MenuItem>
        <MenuItem value="NOTPAID">Not Paid</MenuItem>
      </Select>
    </FormControl>
  )
}

export default PaymentStatusFilter
