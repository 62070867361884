import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import StreetviewIcon from '@mui/icons-material/Streetview'
import NearMeIcon from '@mui/icons-material/NearMe'
import { isPickUpLot } from 'utils/dispatchutils'
import { propOr } from 'ramda'

export function openStreetView(lat, long) {
  const url = `https://maps.google.com/maps?q&layer=c&cbll=${lat},${long}`
  const win = window.open(url, 'streetViewTab', '_blank')
  win.focus()
}

const AddressWithStreetView = ({ data, onClickLocation }) => {
  const sourceOrDestination = isPickUpLot ? data.source : data.destination
  const latitude = propOr(null, 'latitude', sourceOrDestination)
  const longitude = propOr(null, 'longitude', sourceOrDestination)
  const pano_id = propOr(null, 'pano_id', sourceOrDestination)
  const hasStreetView = Boolean(pano_id)
  const tooltipText = hasStreetView
    ? 'Look into street view at this location'
    : `Street view doesn't exist for this location`

  const handleClick = () => {
    if (hasStreetView) {
      openStreetView(latitude, longitude)
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <Tooltip title={<span style={{ fontSize: '1.3em' }}>{tooltipText}</span>}>
        <IconButton onClick={handleClick} style={{ padding: '3px' }}>
          <StreetviewIcon
            sx={{ color: hasStreetView ? '#f2711c' : '#ccc' }}
            fontSize="small"
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={
          <span style={{ fontSize: '1.3em' }}>Zoom into the Location</span>
        }
      >
        <IconButton onClick={onClickLocation} style={{ padding: '3px' }}>
          <NearMeIcon sx={{ color: 'black' }} fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default AddressWithStreetView
