export const initialLoginData = {
  inputEmail: '',
  sendCodeTo: '',
  sendCodeMode: '',
  inputPassword: '',
  inputVerifyCode: '',
  inputNewPassword: '',
  inputConfirmPassword: '',
  userProfileFound: {
    entityId: '',
    entityType: '',
    phoneNumbers: [],
    externalUserId: '',
  },
  loginLoading: false,
  inputPhoneNumber: '',
  inputKeepSignedIn: true,
}

export const truckTypeOrder = [
  '1C',
  '2C',
  '2F',
  '3C',
  '3F',
  '4C',
  '5C',
  '6C',
  '7C',
  '8C',
  '9C',
  '10C',
  '10+C',
]

export const paymentMethodMapper = {
  BNKTR: 'Bank Transfer',
  CHECK: 'Check',
  C: 'Check',
  A: 'Bank Transfer',
}
