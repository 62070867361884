import { atom } from 'jotai'
import { initialLoginData } from '../index'
import { appNavigatorItemsConst } from 'constants/sidebarItems'
import { filterConfig } from '../../utils/filterConfig'
import { getDateThirtyDaysAgo, getTodayDate } from 'utils/date'

const defaultView = appNavigatorItemsConst.TRIPS
const defaultSubView = 'assigned'
const defaultFilterAtom = { tripsView: { ...filterConfig.assigned } }

export const isAuthorizedAtom = atom(false)
export const loadingAtom = atom(true)
export const loginInputDataAtom = atom({ ...initialLoginData })
export const externalUserDataAtom = atom({})
export const selectedViewAtom = atom(defaultView)
export const selectedSubViewAtom = atom(defaultSubView)
export const AccountDialogAtom = atom(false)
export const ContactDialogAtom = atom(false)
export const DriverScheduleDialogAtom = atom(false)
export const skipFilterAtom = atom(false)
export const selectedNumberAtom = atom('')
export const accountStatusAtom = atom('default')
export const tableRecordsAtom = atom()
export const dataFetchLoadingAtom = atom(true)
export const searchTermAtom = atom('')
export const selectedRecordsAtom = atom()
export const truckTypesAtom = atom({
  key: 'truckTypes',
  default: [],
})
export const personnelDataAtom = atom([])
export const workScheduleAtom = atom({})
export const referenceDataAtom = atom([])
export const rowDataAtom = atom({})
export const actionCodeAtom = atom(null)
export const selectedFiltersAtom = atom(defaultFilterAtom)
export const globalPostAtom = atom(false)
export const businessDaysAtom = atom([])
export const statesAtom = atom([])
export const pageAtom = atom(0)
export const rowsPerPageAtom = atom(20)
export const customPageAtom = atom(0)
export const customRowsPerPageAtom = atom(10)
export const declineReasonsAtom = atom({})
export const catViewAtom = atom(true)
export const selectedRowAtom = atom([])
export const catAssocListAtom = atom([])
export const mapViewAtom = atom(false)
export const sortByAtom = atom({})
export const resolveIssueAtom = atom(false)
export const completedRowDataAtom = atom([])
export const refreshDataAtom = atom(false)

export const paymentStatusAtom = atom('PAID')
export const tripFromDateAtom = atom(getDateThirtyDaysAgo())
export const tripToDateAtom = atom(getTodayDate())
// default
//changePassword
