import React, { useState, useMemo, useEffect } from 'react'
import {
  Dialog,
  Box,
  Button,
  TextField,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Divider,
  Typography,
  Alert,
  MenuItem,
} from '@mui/material'
import { useAtom } from 'jotai'
import CloseIcon from '@mui/icons-material/Close'
import { useSnackbar } from 'notistack'
import ImageUploadBox from './imageUploadBox'
import AddressAutocomplete from 'utils/geoSuggest'
import { styles } from './truckStyles'
import { truckTypeMap } from 'constants/columns'
import {
  personnelDataAtom,
  rowDataAtom,
  statesAtom,
  actionCodeAtom,
  globalPostAtom,
} from 'constants/atoms'
import { isEmpty, pathOr, propOr } from 'ramda'
import {
  handleAddTruck,
  handleUpdateTruck,
  uploadDocument,
  removeTruckAssociation,
} from 'services/truckManagementApi'
import { getImageURL } from 'utils/dispatchutils'
import { useStyles } from 'utils/styles'
import { truckManagementLabels } from 'utils/labels'
import ConfirmationDialog from '../../customInputFields/dialog'
import { ACTION_CODES } from 'constants/actionCodes'

const initialFormData = {
  addressLine1: '',
  city: '',
  state: '',
  zip: '',
  truckType: '',
  associatedDriver: '',
  licensePlate: '',
  licenseState: '',
}

const TruckDialog = ({ onClose }) => {
  const [truckInfo] = useAtom(rowDataAtom)
  const [personnelData] = useAtom(personnelDataAtom)
  const [states] = useAtom(statesAtom)
  const [actionCode, setActionCode] = useAtom(actionCodeAtom)
  const [, setGlobalLoading] = useAtom(globalPostAtom)
  const { enqueueSnackbar } = useSnackbar()

  const [formData, setFormData] = useState(initialFormData)
  const [truckImage, setTruckImage] = useState('')
  const [licensePlateImage, setLicensePlateImage] = useState('')
  const [openConfirmationDialog, setConfirmationDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const editMode = truckInfo && !isEmpty(truckInfo)

  const {
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    truckType,
    associatedDriver,
    licensePlate,
    licenseState,
  } = formData

  useEffect(() => {
    if (!isEmpty(truckInfo)) {
      const {
        license_plate: licensePlate,
        license_plate_state_code: licenseState,
        truck_type_code: truckType,
        city,
        state,
        postal_code1: zip,
        line1,
        line2,
        vendor_personnel: vendorPersonnel,
        license_plate_images = [],
        driver_side_view_images = [],
      } = truckInfo
      const vendorPersonnelId = propOr(
        '',
        'vendor_personnel_id',
        vendorPersonnel,
      )
      const licenseImage = getImageURL(license_plate_images, 'H')
      const truckImage = getImageURL(driver_side_view_images, 'H')

      setFormData({
        licensePlate,
        licenseState,
        truckType,
        addressLine1: line1,
        addressLine2: line2,
        city,
        state,
        zip,
        associatedDriver: vendorPersonnelId,
      })

      setTruckImage(truckImage)
      setLicensePlateImage(licenseImage)
    }
  }, [truckInfo])

  const isFormValid = useMemo(
    () => licensePlate && licenseState && truckType && addressLine1,
    [licensePlate, licenseState, truckType, addressLine1],
  )

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }))
  }

  const getPayload = () => ({
    vendor_personnel_id: associatedDriver,
    license_plate: licensePlate,
    truck_type_code: truckType,
    location: {
      city,
      state,
      postal_code1: zip,
      line1: addressLine1,
      line2: addressLine2,
      country: 'USA',
      latitude: formData.latitude,
      longitude: formData.longitude,
    },
    license_plate_state_code: formData.licenseState,
  })
  const isUrl = (string) =>
    typeof string === 'string' &&
    (string.startsWith('http://') || string.startsWith('https://'))

  const getValidImagesToUpload = (truckImage, licensePlateImage) => {
    const imagesObj = []
    if (truckImage && !isUrl(truckImage)) {
      imagesObj.push({
        file: truckImage,
        code: 'DRSDV',
      })
    }
    if (licensePlateImage && !isUrl(licensePlateImage)) {
      imagesObj.push({
        file: licensePlateImage,
        code: 'LICPL',
      })
    }

    return imagesObj
  }

  const uploadFiles = (filesArr, addMetaData, successMessage) => {
    filesArr.forEach(async ({ file, code }, index) => {
      const docType = 'IMAGE'
      uploadDocument(file, '', code, docType, addMetaData)
        .then(({ data = {}, status }) => {
          enqueueSnackbar(truckManagementLabels.imageUploadSuccess(code), {
            variant: 'success',
          })
        })
        .catch((error) => {
          const errorResponse = pathOr(
            '',
            ['response', 'data', 'message'],
            error,
          )
          enqueueSnackbar(errorResponse, { variant: 'error' })
        })
        .finally(() => {
          if (index === filesArr.length - 1) {
            successMessage()
          }
        })
    })
  }

  const handleSubmit = () => {
    setLoading(true)
    if (!isEmpty(truckInfo)) {
      const truckId = truckInfo?.truck_id
      handleUpdateTruck({
        payload: getPayload(),
        truckId: truckInfo?.truck_id,
      })
        .then(async () => {
          const imagesToUpload = getValidImagesToUpload(
            truckImage,
            licensePlateImage,
          )
          if (imagesToUpload.length) {
            await uploadFiles(
              imagesToUpload,
              {
                reference_code_1: 'TRUCK',
                reference_id_1: truckId,
                media_status_code: 'APRVD',
              },
              () => {
                enqueueSnackbar(truckManagementLabels.updateTruckSuccess, {
                  variant: 'success',
                })
                closeDialog()
                setLoading(false)
              },
            )
          } else {
            enqueueSnackbar(truckManagementLabels.updateTruckSuccess, {
              variant: 'success',
            })
            closeDialog()
            setLoading(false)
          }
        })
        .catch((err) => {
          const errorResponse = pathOr(
            '',
            ['response', 'data', 'message', 0],
            err,
          )
          enqueueSnackbar(errorResponse, { variant: 'error' })
          setLoading(false)
        })
    } else {
      handleAddTruck({ payload: getPayload() })
        .then(async (response) => {
          const truckId = pathOr('', ['data', 'truck_id'], response)

          const imagesToUpload = getValidImagesToUpload(
            truckImage,
            licensePlateImage,
          )
          if (imagesToUpload.length) {
            await uploadFiles(
              imagesToUpload,
              {
                reference_code_1: 'TRUCK',
                reference_id_1: truckId,
                media_status_code: 'APRVD',
              },
              () => {
                enqueueSnackbar(truckManagementLabels.addNewTruckSuccess, {
                  variant: 'success',
                })
                closeDialog()
                setLoading(false)
              },
            )
          } else {
            enqueueSnackbar(truckManagementLabels.addNewTruckSuccess, {
              variant: 'success',
            })
            closeDialog()
            setLoading(false)
          }
        })
        .catch((err) => {
          const errorResponse = pathOr(
            '',
            ['response', 'data', 'message', 0],
            err,
          )
          enqueueSnackbar(errorResponse, { variant: 'error' })
          setLoading(false)
        })
    }
  }

  const handleRemoveTruck = () => {
    setLoading(true)
    const truckId = propOr('', 'truck_id', truckInfo)
    removeTruckAssociation(truckId)
      .then((res) => {
        if (res) {
          enqueueSnackbar(truckManagementLabels.removeTruckSuccess, {
            variant: 'success',
          })
          closeDialog()
        }
      })
      .catch((err) => {
        enqueueSnackbar(truckManagementLabels.removeTruckFailure, {
          variant: 'error',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const resetFields = () => {
    setFormData(initialFormData)
  }

  const closeDialog = () => {
    resetFields()
    setActionCode('')
    setGlobalLoading(true)
  }

  const handleAddressSelect = (selectedAddress) => {
    const { addressLine1, city, state, zip, latitude, longitude } =
      selectedAddress
    setFormData((prevData) => ({
      ...prevData,
      addressLine1,
      city,
      state,
      zip,
      latitude,
      longitude,
    }))
  }

  const getDialogTitle = () => {
    if (!isEmpty(truckInfo)) return 'Edit Truck'

    return 'Add Truck'
  }
  const classes = useStyles()

  return (
    <Dialog
      open={actionCode === ACTION_CODES.ADD_TRUCK}
      onClose={closeDialog}
      maxWidth="md"
      fullWidth
    >
      <form>
        <DialogTitle>
          <div style={{ color: 'white' }}>
            <Typography
              classes={{
                root: classes.root,
              }}
            >
              {getDialogTitle()}
            </Typography>
          </div>
          <IconButton onClick={closeDialog} style={styles.closeBtn}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box style={styles.box}>
            <Box style={{ width: '40%' }}>
              <ImageUploadBox
                text="Truck Image"
                uploadedImage={(url) => setTruckImage(url)}
                onDeleteImage={() => setTruckImage(null)}
                image={
                  truckImage
                    ? isUrl(truckImage)
                      ? truckImage
                      : URL.createObjectURL(truckImage)
                    : ''
                }
              />
              <ImageUploadBox
                text="License Image"
                uploadedImage={(url) => setLicensePlateImage(url)}
                onDeleteImage={() => setLicensePlateImage(null)}
                image={
                  licensePlateImage
                    ? isUrl(licensePlateImage)
                      ? licensePlateImage
                      : URL.createObjectURL(licensePlateImage)
                    : ''
                }
              />
              <Box mt={2} display="flex" justifyContent="space-between">
                <Typography>
                  Supported Filetypes: <strong>JPG, PNG</strong>
                </Typography>
                <Typography>
                  Total upload file size limit: <strong>16MB</strong>
                </Typography>
              </Box>
              <Alert severity="warning" style={{ marginTop: '10px' }}>
                Ensure Images are clear and centered. For the Truck Side Image,
                you should be able to clearly ready company name. For License
                plate Image, you should be able to clearly read license plate.
              </Alert>
            </Box>
            <Divider {...styles.verticalDivider} />
            <Box style={{ ...styles.column, width: '60%' }}>
              <Box style={styles.box}>
                <TextField
                  label="License Plate"
                  variant="outlined"
                  style={{ ...styles.textField, marginRight: '10px' }}
                  required
                  value={licensePlate}
                  onChange={(e) => handleChange('licensePlate', e.target.value)}
                  inputProps={{ maxLength: 7 }}
                />
                <TextField
                  label="License State"
                  variant="outlined"
                  select
                  style={styles.textField}
                  required
                  value={licenseState}
                  onChange={(e) => handleChange('licenseState', e.target.value)}
                  SelectProps={{
                    MenuProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                >
                  {states?.map(({ stateCode, stateName }) => (
                    <MenuItem key={stateCode} value={stateCode}>
                      {stateName}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <TextField
                label="Truck Type"
                variant="outlined"
                select
                style={styles.textField}
                value={truckType}
                onChange={(e) => handleChange('truckType', e.target.value)}
                required
              >
                {Object.entries(truckTypeMap).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </TextField>
              <Box {...styles.homeLocationBox}>
                <Box mb={2}>
                  <strong>Truck Home Location</strong>
                </Box>
                <AddressAutocomplete
                  style={styles.textField}
                  label="Address Line 1"
                  value={addressLine1}
                  onAddressSelect={handleAddressSelect}
                />
                <TextField
                  label="Line 2"
                  variant="outlined"
                  style={styles.textField}
                  value={addressLine2}
                  onChange={(e) => handleChange('addressLine2', e.target.value)}
                />
                <Box style={styles.box}>
                  <TextField
                    label="City"
                    variant="outlined"
                    style={{ ...styles.textField, marginRight: '10px' }}
                    value={city}
                    disabled
                  />
                  <TextField
                    label="ZIP"
                    variant="outlined"
                    style={styles.textField}
                    value={zip}
                    disabled
                  />
                </Box>
                <TextField
                  label="State"
                  variant="outlined"
                  style={styles.textField}
                  value={state}
                  disabled
                />
              </Box>
              <TextField
                label="Associated Driver"
                variant="outlined"
                select
                style={styles.textField}
                value={associatedDriver}
                onChange={(e) =>
                  handleChange('associatedDriver', e.target.value)
                }
                SelectProps={{
                  MenuProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {personnelData.map(
                  ({ vendorPersonnelId, firstName, lastName, status }) => (
                    <MenuItem
                      key={vendorPersonnelId}
                      value={vendorPersonnelId}
                      style={{ color: status === 'A' ? 'black' : 'grey' }}
                    >
                      {firstName} {lastName}
                    </MenuItem>
                  ),
                )}
              </TextField>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={editMode ? { justifyContent: 'space-between' } : {}}>
          {editMode ? (
            <Button
              variant="contained"
              color="error"
              onClick={() => setConfirmationDialog(true)}
              disabled={loading}
            >
              Remove Truck
            </Button>
          ) : (
            ''
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!isFormValid || loading}
          >
            {editMode ? 'Save' : 'Add'}
          </Button>
        </DialogActions>
      </form>

      <ConfirmationDialog
        open={openConfirmationDialog}
        onClose={() => setConfirmationDialog(false)}
        title="Remove Truck"
        content="Are you sure you want to remove the truck?"
        actions={[
          {
            name: 'cancel',
            onClick: () => setConfirmationDialog(false),
            disabled: loading,
            variant: 'contained',
            sx: {
              color: 'black',
              backgroundColor: 'white',
              ':hover': { backgroundColor: 'white', color: 'black' },
            },
          },
          {
            name: 'confirm',
            onClick: () => handleRemoveTruck(),
            disabled: loading,
            variant: 'contained',
          },
        ]}
      />
    </Dialog>
  )
}

export default TruckDialog
