import React, { useEffect } from 'react'
import { TextField, InputAdornment, IconButton } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useAtom } from 'jotai'
import { placeholdersMapping } from 'constants/sidebarItems'
import { selectedViewAtom, searchTermAtom } from 'constants/atoms'
import locale from 'utils/locale'
const Search = () => {
  const [searchTerm, setSearchTerm] = useAtom(searchTermAtom)
  const [selectedView] = useAtom(selectedViewAtom)

  useEffect(() => {
    setSearchTerm('')
  }, [selectedView, setSearchTerm])

  const placeholderText = placeholdersMapping[selectedView] || 'Search'
  return (
    <TextField
      variant="outlined"
      size="small"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      placeholder={locale(placeholderText)}
      style={{
        width: '20%',
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default Search
