import React from 'react'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import StormIcon from '@mui/icons-material/Storm'
import WorkIcon from '@mui/icons-material/Work'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PeopleIcon from '@mui/icons-material/People'
import PaymentIcon from '@mui/icons-material/Payment'
import locale from 'utils/locale'

export const appNavigatorItems = {
  navigatorWidth: 265,
  navigatorItems: [
    {
      id: 0,
      text: locale('Lots'),
      key: 'lotsView',
      icon: <DirectionsCarIcon />,
      options: [
        {
          id: 0,
          text: locale('Distributed'),
          key: 'distributed',
          parentKey: 'lotsView',
        },
        {
          id: 1,
          text: locale('Completed'),
          key: 'completed',
          parentKey: 'lotsView',
        },
        {
          id: 2,
          text: locale('Issues Reported By Me'),
          key: 'reportedIssues',
          parentKey: 'lotsView',
        },
        {
          id: 3,
          text: locale('Issues Assigned To Me'),
          key: 'assignedIssues',
          parentKey: 'lotsView',
        },
      ],
    },
    {
      id: 1,
      text: locale('Trips'),
      key: 'tripsView',
      icon: <LocationOnIcon />,
      options: [
        {
          id: 0,
          text: locale('Assigned'),
          key: 'assigned',
          parentKey: 'tripsView',
        },
        {
          id: 1,
          text: locale('Acknowledged'),
          key: 'acknowledged',
          parentKey: 'tripsView',
        },
        {
          id: 2,
          text: locale('Declined Trips Reported By Me'),
          key: 'reportedDeclinedTrips',
          parentKey: 'tripsView',
        },
        {
          id: 3,
          text: locale('Declined Trips Assigned To Me'),
          key: 'reportedAssignedTrips',
          parentKey: 'tripsView',
        },
      ],
    },
    {
      id: 2,
      text: locale('Drivers'),
      key: 'driversView',
      icon: <PeopleIcon />,
    },
    {
      id: 3,
      text: locale('Work Requests'),
      key: 'workRequestsView',
      icon: <WorkIcon />,
    },
    {
      id: 4,
      text: locale('CAT Tow Providers'),
      key: 'catTowProvidersView',
      icon: <StormIcon />,
    },
    {
      id: 5,
      text: locale('Truck Management'),
      key: 'truckManagementView',
      icon: <LocalShippingIcon />,
    },
    {
      id: 6,
      text: locale('Earning Summary'),
      key: 'earningSummaryView',
      icon: <PaymentIcon />,
    },
  ],
}

export const appNavigatorItemsConst = {
  LOTS: 'lotsView',
  TRIPS: 'tripsView',
  DRIVERS: 'driversView',
  WORK_REQUESTS: 'workRequestsView',
  CAT_TOW_PROVIDERS: 'catTowProvidersView',
  TRUCK_MANAGEMENT: 'truckManagementView',
  EARNING_SUMMARY: 'earningSummaryView'
}

export const appNavigatorDisplayNames = {
  [appNavigatorItemsConst.LOTS]: locale('Lots'),
  [appNavigatorItemsConst.TRIPS]: locale('Trips'),
  [appNavigatorItemsConst.DRIVERS]: locale('Drivers'),
  [appNavigatorItemsConst.WORK_REQUESTS]: locale('Work Requests'),
  [appNavigatorItemsConst.CAT_TOW_PROVIDERS]: locale('Cat Tow Providers'),
  [appNavigatorItemsConst.TRUCK_MANAGEMENT]: locale('Truck Management'),
  [appNavigatorItemsConst.EARNING_SUMMARY]: locale('Earning Summary'),
}

export const placeholdersMapping = {
  lotsView: 'Search with Lot Number',
  tripsView: 'Search with Trip ID',
  driversView: 'Search with Driver Name',
  catTowProvidersView: 'Search with Driver Name',
  truckManagementView: 'Search with License Plate Number',
  earningSummaryView: 'Search With Lot Number'
}

export const viewSearchColumns = {
  lotsView: 'lot_number',
  tripsView: 'trip_id',
  truckManagementView: 'license_plate',
}
