import {
  propOr,
  pathOr,
  path,
  propEq,
  any,
  and,
  converge,
  or,
  complement,
  isEmpty,
  isNil,
  head,
  compose,
  replace,
} from 'ramda'
import { getItem } from './localstorage'

import { isSameOrBeforeToday, parseDate, getDateFromDateTime } from 'utils/date'
import { viewSearchColumns } from 'constants/sidebarItems'
import { paymentMethodMapper } from 'constants'

export const getRoleById = (role) =>
  ({
    1: 'VENDOR',
    2: 'GROUP',
    3: 'DRIVER',
  }[role])

export const getRole = path(['personnel_data', 'role_id'])
export const isActiveIssue = (lot) => lot.active_issue_flag === 'Yes'
export const isLotStatusInRange = (min, max) => (lot) =>
  parseInt(lot.dispatch_status) >= min && parseInt(lot.dispatch_status) <= max
export const isBelongsToUser = propOr(false, 'belongs_to_user')
export const isTripDistributable = (lot) => {
  return lot.distributable
}
export const isDeclinedLot = ({ declined_flag: declinedFlag }) =>
  declinedFlag === 1
export const isAnyUnreadIssue = (lot) =>
  pathOr(0, ['unread_resolved_issues', 'length'], lot) > 0
export const isIssueAssignedToMe = (lot) => {
  const userData = getItem('userData')
  const roleId = pathOr('', ['vendor', 'role_id'], userData)
  const role = getRoleById(roleId) || ''
  return (
    pathOr('', ['active_issue', 'current_entity_type'], lot).toLowerCase() ===
    role.toLowerCase()
  )
}
export const isIssueRaisedByMe = (lot) => {
  const userData = getItem('userData')
  const roleId = pathOr('', ['vendor', 'role_id'], userData)
  const role = getRoleById(roleId) || ''
  return (
    pathOr('', ['active_issue', 'created_by_type'], lot).toLowerCase() ===
    role.toLowerCase()
  )
}

export const isTripDeclinedToUser = propOr(false, 'declined_to_user')
export const isTripRejected = propEq('R', 'trip_status')
export const isTripAcknowledged = propEq(true, 'acknowledged')

//pcard utils
export const isPCardFlagN = propEq('N', 'pcard_flag')
export const isDriverRole = propEq(3, 'role_id')
export const isActive = propEq('A', 'status')
export const isPCardFlagY = propEq('Y', 'pcard_flag')

export const dispatchStatusMapper = {
  'Pending Trip Build': '200',
  'Pending Assignment': '210',
  'Pending Vendor Acknowledgement': '220',
  'Pending Group Acknowledgement': '230',
  'Pending Driver Acknowledgement': '240',
  'Pending Driver Start': '250',
  'Pending Form Submission': '260',
  'Pending Vehicle Arrival': '270',
  'Pending Vehicle Check In': '280',
  'Pending Paperwork Verification': '290',
  Completed: '300',
}

export const reverseDispatchStatusMapper = {
  200: 'Awaiting Trip Build',
  210: 'Awaiting Driver Assignment',
  220: 'Awaiting Vendor Acknowledgement',
  230: 'Awaiting Group Acknowledgement',
  240: 'Awaiting Driver Acknowledgement',
  250: 'Awaiting In Progress',
  260: 'Awaiting Form Submission',
  270: 'Awaiting Arrival',
  280: 'Awaiting Check In',
  290: 'Awaiting Pickup Verification',
  300: 'Completed',
}

export const statusMap = {
  A: 'Active',
  I: 'Inactive',
}

export const roleMap = {
  1: 'Group Manager',
  2: 'Company Manager',
  3: 'Driver',
}

export const dispatchStatusColor = {
  200: '#FFFF00', // Yellow
  210: '#FFFF00', // Yellow
  220: '#A9A9A9', // Light Grey
  230: '#808080', // Grey
  240: '#696969', // Dark Grey
  250: '#FFD700', // Light Orange
  260: '#FFA500', // Orange
  270: '#FF8C00', // Dark Orange
  280: '#BFFF00', // Light Green
  290: '#00CC00', // Green
  300: '#008000', // Dark Green
}

export const statusTextColor = (value) =>
  [200, 210, 250, 280].includes(Number(value)) ? 'black' : 'white'

export const formAddress = (obj) => {
  return [
    obj?.address_line1 ||
      obj?.lot_site_address_line1 ||
      obj?.location_address_line1 ||
      obj?.line1 ||
      '',
    obj?.address_line2 ||
      obj?.lot_site_address_line2 ||
      obj?.location_address_line2 ||
      obj?.line2 ||
      '',
    obj?.city || obj?.lot_site_city || obj?.location_city || '',
    obj?.state_code ||
      obj?.lot_site_state_code ||
      obj?.location_state ||
      obj?.state ||
      '',
    [
      obj?.location_zip ||
        obj?.postal_code ||
        obj?.postal_code1 ||
        obj?.full_postal_code ||
        obj?.lot_site_postal_code ||
        obj?.zip ||
        '',
      obj?.postal_code2 || obj?.lot_site_postal_code2 || obj?.zpp || '',
    ]
      .filter((a) => a)
      .join(' - '),
  ]
    .filter((a) => a)
    .join(', ')
}

export const moveToNextItemInMultiView = (
  currentIndex,
  createdIssues,
  rowData,
  identifier,
) => {
  let nextIndex = currentIndex
  while (
    createdIssues.includes(rowData[nextIndex][identifier]) ||
    (nextIndex === currentIndex &&
      createdIssues.includes(rowData[nextIndex][identifier]))
  ) {
    nextIndex = (nextIndex + 1) % rowData.length
    if (nextIndex === currentIndex) break
  }
  return nextIndex
}

export const getSourceLocationId = pathOr('', ['source', 'id'])
export const getDestinationLocationId = pathOr('', ['destination', 'id'])

export const getTripType = propOr('P', 'trip_type_code')
export const isLocToLocTransport = (lot, tripType) =>
  tripType === 'T' &&
  getSourceLocationId(lot).includes('loc') &&
  getDestinationLocationId(lot).includes('loc')

const getSourceGeometry = (lot) => ({
  lat: path(['source', 'latitude'], lot),
  lng: path(['source', 'longitude'], lot),
  pano_id: path(['source', 'pano_id'], lot),
  exists: path(['source', 'exists'], lot),
  accurate: path(['source', 'accurate'], lot),
  partial_match: path(['source', 'partial_match'], lot),
  residence: path(['source', 'residence'], lot),
})

const getDestinationGeometry = (lot) => ({
  lat: path(['destination', 'latitude'], lot),
  lng: path(['destination', 'longitude'], lot),
  pano_id: path(['destination', 'pano_id'], lot),
  exists: path(['destination', 'exists'], lot),
  accurate: path(['destination', 'accurate'], lot),
  partial_match: path(['destination', 'partial_match'], lot),
  residence: path(['destination', 'residence'], lot),
})
export const getResponsiblePartyName = propOr('', 'responsible_party_name')
export const isItEmptyOrNil = converge(or, [isEmpty, isNil])
export const isNotEmptyNotNil = complement(isItEmptyOrNil)
export const getRunId = propOr('', 'current_trip_id')
export const isTowProviderAssigned = compose(
  isNotEmptyNotNil,
  getResponsiblePartyName,
)
export const isTowProviderNotAssigned = complement(isTowProviderAssigned)
const getPromisedTripDate = propOr('', 'promised_date')
const isItTempRun = (lot) => getRunId(lot).includes('-temp')
const isRunAssigned = compose(isNotEmptyNotNil, getRunId)
const isRunAssignedNotTowProviderAssigned = converge(and, [
  isRunAssigned,
  isTowProviderNotAssigned,
])
const isUnassigned = converge(and, [
  complement(isTowProviderAssigned),
  complement(isRunAssignedNotTowProviderAssigned),
])
/**
 * @param lot: for which we want to get location information
 * @returns {any} Geometry of location depending on tripType
 */
export function getAddressLocationInfo(lot) {
  const tripType = getTripType(lot)
  return tripType === 'P' || isLocToLocTransport(lot, tripType)
    ? getSourceGeometry(lot)
    : getDestinationGeometry(lot)
}

export function isLotLatAndLngNotValid(lot) {
  const geometry = getAddressLocationInfo(lot)
  return geometry.partial_match || geometry.lat === 0.1 || geometry.lng === 0.1
}

export const anyLotPromisedTripDateIsBeforeToday = any((lot) =>
  and(
    isSameOrBeforeToday(getPromisedTripDate(lot)),
    converge(or, [isUnassigned, isItTempRun], lot),
  ),
)

export const getIsScheduledText = ({
  is_scheduled: isScheduled,
  scheduled_date: scheduledDate,
  scheduled_time_from: scheduledTimeFrom = '',
  scheduled_time_to: scheduledTimeTo = '',
}) => {
  const scheduledTime =
    scheduledTimeFrom && scheduledTimeTo
      ? `${scheduledTimeFrom} to ${scheduledTimeTo}`
      : ''
  const showScheduledDate = isScheduled === 1 && scheduledDate
  return {
    scheduledDate: showScheduledDate && parseDate(scheduledDate),
    scheduledText:
      showScheduledDate && `${parseDate(scheduledDate)} ${scheduledTime}`,
  }
}

// export CSV utils

export const downloadCSV = (fileName, data) => {
  const link = document.createElement('a')
  const blob = new Blob([data], {
    type: 'application/csv;charset=utf-8;',
  })
  const url = URL.createObjectURL(blob)
  link.href = url
  link.style = 'visibility:hidden'
  link.download = `${fileName}-${Date.now()}.csv`
  link.click()
}

export const removeQuotes = (data) => replace(/"/g, '', toString(data))
export const addNewLine = (data) => `${data},\r\n`

export const getCSVHeaderRow = (columnsArray) => {
  const headerRow = columnsArray
    .filter((column) => column.id !== 'actions')
    .map((column) => column.label)
  return headerRow.join(',')
}

export const getCsvData = (finalData) => {
  return finalData
    .map((data) => {
      const sanitizedValues = Object.values(data).map((value) => {
        if (value === undefined || value === null) {
          return ''
        } else if (typeof value === 'object') {
          return JSON.stringify(value)
        } else {
          return value.toString()
        }
      })
      return sanitizedValues.join(', ')
    })
    .join(',\r\n')
}

export const getFieldDataForLotsView = (results, columns) =>
  results.map((data) => {
    return columns
      .filter(({ id }) => id !== 'actions')
      .map((column) => {
        const { id } = column
        let value = data[id]
        if (id === 'lot_number') {
          value = `${data['lot_number']}-${data['lot_suffix']}`
        } else if (id === 'tripConfirmationDate') {
          value = data.tripConfirmationDate
            ? getDateFromDateTime(data.tripConfirmationDate, 'MM/dd/yyyy')
            : ''
        } else if (id === 'paymentDate') {
          value = data.paymentDate
            ? getDateFromDateTime(data.paymentDate, 'MM/dd/yyyy')
            : ''
        } else if (id === 'is_residence') {
          value = data.is_resident ? 'Yes' : 'No'
        } else if (id === 'src_nm') {
          const pickupData = getPickup(propOr({}, 'source', data))
          value = pickupData.replace(/, /g, '; ')
        } else if (id === 'src_city') {
          value = pathOr('', ['source', 'city'], data)
        } else if (id === 'src_zip') {
          value = pathOr('', ['source', 'zip'], data)
        } else if (id === 'dest_nm') {
          const destinationData = getDestination(
            propOr({}, 'destination', data),
          )
          value = destinationData.replace(/, /g, '; ')
        } else if (id === 'dispatch_status') {
          value = reverseDispatchStatusMapper[data.dispatch_status]
        } else if (id === 'message_type_code') {
          value = pathOr('', ['active_issue', 'message_type_description'], data)
        } else if (id === 'created_by') {
          value = pathOr('', ['active_issue', 'created_by'], data)
        } else if (id === 'driverName') {
          value = `${data['vendorPersonnelFirstName']} ${data['vendorPersonnelLastName']}`
        } else if (id === 'paymentMethod') {
          value = propOr(
            data?.paymentMethod,
            data?.paymentMethod,
            paymentMethodMapper,
          )
        } else if (id === 'amount') {
          value = `$${parseFloat(data?.amount).toFixed(2)}`
        }
        return value
      })
  })

export const getPickup = (parts, map) => {
  if (!parts) {
    return ''
  }
  const addressParts = !map
    ? ['name', 'line_1']
    : ['line_1', 'city', 'state', 'zip']
  return addressParts
    .map((part) => propOr('', part, parts))
    .filter((part) => part)
    .join(', ')
}

export const getDestination = (parts) => {
  if (!parts) {
    return ''
  }
  const addressParts = ['name', 'line_1', 'city', 'zip']
  return addressParts
    .map((part) => parts[part])
    .filter((part) => part)
    .join(', ')
}

export const filterDataByView = (data, selectedView, searchTerm) => {
  const searchColumn = viewSearchColumns[selectedView]

  if (
    selectedView === 'driversView' ||
    selectedView === 'catTowProvidersView'
  ) {
    return searchTerm
      ? data.filter((item) => {
          const fullName = `${item.first_name} ${item.last_name}`.toLowerCase()
          return fullName.includes(searchTerm.toLowerCase())
        })
      : data
  } else {
    return searchTerm && searchColumn
      ? data.filter((item) =>
          String(item[searchColumn])
            .toLowerCase()
            .includes(searchTerm.toLowerCase()),
        )
      : data
  }
}

export const getImageURL = (images = [], type = 'T') => {
  const imageFound = images && images.find(({ name }) => name === type)

  if (imageFound) return imageFound.url
  return ''
}

export const getAssoicatedDriverName = (personnelInfo = {}) => {
  const firstName = propOr('', 'first_name', personnelInfo)
  const lastName = propOr('', 'last_name', personnelInfo)

  return firstName + ' ' + lastName
}

export const isPickUpLot = (lot) =>
  ['P'].includes(lot.trip_type_code) ||
  isLocToLocTransport(lot, lot.trip_type_code)
